// override bootstrap 4 variables

// $blue: #2196f3;
// $indigo: #536dfe;
// $purple: #9c27b0;
// $pink: #ff4081;
// $red: #f44336;
// $orange: #ff9800;
// $yellow: #ffeb3b;
// $green: #4caf50;
// $teal: #009688;
// $cyan: #00bcd4;
// $white: #fff;

// สีตรีมของการ์ดหรือกล่องข้อความ เราสามารถเอาคำสั่งพวกนี้ไปใส่ในทีมก็จะได้
$theme-colors: (
  "primary": #6a82fb,
  "secondary": #fc5c7d,
  "success": #45b649,
  "danger": #f85032,
  "warning": #ffd700,
  "info": #00c9ff,
  // ปุ่ม
    "btn1": #3f8dc4,
  "btn2": #f3c62b,
  "btn3": #d01919,
  "silver": #c0c0c0,
  "new-btn": #2196f3,
);

$enable-gradients: true;

// card
//
$card-cap-bg: #fff;

// sidebar ปรับขนาด
$cr-sidebar-width: 224px;

// ส่วนบังคับให้พวกตัวอักษรอยู่ตรงกลางของตาราง
// td,tr {
//   text-align: center;
// }
// .t1{
//   text-align: left;
// }
// .tc1{
//   background-color: white;
//   color: black;
// }

.btnbot {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0;
}

// set ตัวเลขของตารางให้อยู่ตรงกลาง
.no,
.amount1,
.action {
  text-align: center;
}

th {
  color: #2196f3;
}

// .page-item .page-link {
//   background-color: #3F8DC4;
// }
