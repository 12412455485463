// Sider color สีของ navbar 

.cr-sidebar {
  // @include media-breakpoint-down(md) {
  //   display: none;
  // }

// ทำให้ sider อยู่ซ้ายไม่กินทั้งจอ

  background-color: #334261;
// พิ้นหลังอันนี้คือโกงทำให้สีมันสวย ที่จริงสีแย่มาก
  position: fixed;
  top: 0;
  bottom: 0;
  left: -$cr-sidebar-width;
  z-index: 5;
  width: $cr-sidebar-width;
  font-weight: 150;
  color:#fff;
  background-position: center center;
  background-size: cover;
  transition: left 0.1s ease-in;

  + .cr-content {
    transition: margin 0.1s ease-in;
  }

  &::after,
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
  }

  &::before {
    background: #000;
    opacity: 0.33;
  }

// color silder (สีพื้นหลังตรง)
  &::after {
    z-index: 3;
    // sublime light
    background: #334261; /* fallback for old browsers */
    background: linear-gradient(to bottom, #334261, #334261);
    opacity: 1;
  }
// End color silder

  &[data-image]:after {
    opacity: 0.77;
  }

// ส่วนทำให้ตัวหนังสือปรากฎบนเมนู sider
  &__content {
    position: relative;
    z-index: 4;
    height: calc(100vh - 20px);
    padding-bottom: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }

// ทำให้ตัว สามเหลือมหัวลงที่ย่อยเมนูไปอยู่ในลักษณะที่สวย 
  &__nav-item-collapse {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

// ทำให้ไอคอนมีระยะห่างจากเมนู
  &__nav-item-icon {
    // @include media-breakpoint-down(md) {
    //   padding: 0;
    //   margin: 0 auto;
    // }
    width: 1.5rem;
    height: 1.5rem;
    padding-right: 0.5rem;
  }

// ทำให้มีเมนูด้านซ้ายมือ
  &--open {
    left: 0;
    transition: left 0.2s ease-in;

// ทำให้หน้ากับ sider ไม่ซำ้ทับกัน ทำให้เราเปิดปิดเมนูได้
    + .cr-content {
      margin: 0 0 0 $cr-sidebar-width;
      transition: margin 0.2s ease-in;
    }
  }

  .nav {
    margin-top: 20px;

// จัดให้เมนูในหน้าดูเหมาะสมกับ sider
    .nav-item {
      margin: 5px 10px;
      color: theme-color(light);
      border-radius: 4px;
      opacity: 0.86;
    }

//สีของตัวอักษร ของsider  
    .nav-link {
      // @include media-breakpoint-down(md) {
      //   padding: .5rem;
      // }
      color: theme-color(light);

// สีของไฮไลท์แทบเวลาเอาเมาส์จะไปวาง
      &:hover {
        background: rgba(theme-color(light), 0.15);
      }
    }
  }
}


