.cr-search-form {
  position: relative;

  // แต่งไอคอนค้นหา
  &__icon-search {
    position: absolute;
    left: 8px;
  }

  &__input {
    /* stylelint-disable-next-line */
    // ขนาดช่องค้นหา
    padding-left: 35px !important;
  }
}
