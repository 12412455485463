// colors พื้นหลังของ card ในหน้า Home ที่มี 4 กล่องอ่ะ

// ในที่นี้ใช้ linear เป็นการไล่สี โดยคำสั่งเช่น primary เอามาจาก file _variables.scss
.bg-gradient-theme {
  background: linear-gradient(to bottom, theme-color("info"), theme-color("info"));
}
.bg-gradient-theme-top {
  background: linear-gradient(to top, theme-color("primary"), theme-color("secondary"));
}
.bg-gradient-theme-left {
  background: linear-gradient(to left, theme-color("primary"), theme-color("secondary"));
}
.bg-gradient-theme-right {
  background: linear-gradient(to right, theme-color("primary"), theme-color("secondary"));
}
.a1{
  text-align: center;
}
